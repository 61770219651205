












































import { Component, Vue } from 'vue-property-decorator';
import { HomeContent } from '@/types/neuhaus-website/cms/pages/home';
import axios, { APIResponse } from '@/plugins/axios';

/**
 * CMSPageHome Component
 * 
 * @author Kevin Danne <danne@skiba-procomputer.de>
 */
@Component({
    components: {
        'cms-page': () => import('@/components/cms/pages/CMSPage.vue'),
        'cms-component-grid': () => import('@/components/cms/components/CMSComponentGrid.vue'),
        'cms-component-sheet': () => import('@/components/cms/components/CMSComponentSheet.vue'),
        'cms-component-card': () => import('@/components/cms/components/CMSComponentCard.vue')
    }
})
export default class CMSPageHome extends Vue {
    // variable with page content
    private pageContent: HomeContent = {
        id: -1,
        title: 'N/A',
        companyInformations: {
            id: 0,
            rows: []
        },
        contentBox: {
            id: -1,
            backgroundColor: 'grey darken-3',
            color: 'white',
            content: 'N/A'
        },
        pageCards: []
    };

    /**
     * Function will be executed on component load
     * fetches page content
     * 
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private async created() {
        this.pageContent = await this.fetchPageContent();
    }

    /**
     * Fetches page content from API
     * 
     * @returns HomeContent
     * 
     * @author Kevin Danen <danne@skiba-procomputer.de>
     */
    private async fetchPageContent(): Promise<HomeContent> {
        try {
            const response = await axios.get<APIResponse<HomeContent>>('/cms/pages/home')

            if (response.data.status === 'error' || !response.data.data) {
                throw null;
            }

            return response.data.data;
        } catch (err) {
            return this.pageContent;
        }
    }
}
